import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? '#000' : 'transparent')};
    height: 60px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
    height: 80x;
    z-index: 1;
    width: 100%;
    padding: 0 10px;
    max-width: 1100px;

`

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    align-items: center;
    // margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
`

// export const NavPhoneContainer = styled.div`
//     margin-top: 12px;
//     width: 40px;
//     height: 40px;
//     border-radius: 50px;
//     background-color: white;
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: center;
//     justify-content: center;

//     @media screen and (max-width: 768px){
//         position: absolute;
//         top: 5px;
//         left: 120px;
//         transform: translate(0%, 10%);
//         width: 35px;
//         height: 35px;
//         cursor: pointer;
//         display: flex;
//     }
//     @media screen and (max-width: 280px){
//         left: 100px;
//     }
// `

// export const NavPhone = styled.a`
//     color: black;
//     font-size: 25px;
//     display: flex;
// `

export const LogoImg = styled.img`
     max-height: 80px;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const MobileBtnContainer = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 12px;
        right: 80px;
        transform: translate(-70%,30%);
        width: 25px;
        height: 30px;
        cursor: pointer;

    }
`

export const MobileBtn = styled.button`
    font-size: 1.3rem;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    color: #fff;
    background: ${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
    border: none;
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    /* margin-right: -22px;//-22px */

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 60px;
`

export const NavLinks = styled(LinkS)`
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active{
        border-bottom: 3px solid #bf2626;
    }
`

export const NavBtnWrapper = styled.nav`
  display: flex;
  align-items: center;
  padding-left: 20px;

  @media screen and (max-width: 768px){
    display: none;
  }
`
export const NavBtn = styled.button`
    border-radius: 50px;
    background: #fff;
    white-space: nowrap;
    padding: 5px 22px;
    color: #010606;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: 600;
    

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #bf2626;
        color: #010606;
    }
`



