import React from 'react'
import {LoaderContainer} from './LoaderStyle'
import Lottie from 'lottie-react'
import img1 from '../../assets/loader.json'

const Loader = () => {


  return (
    <LoaderContainer>
      <Lottie animationData={img1}/>
    </LoaderContainer>
  )
}

export default Loader