import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaWaze,
  FaGoogle,
  FaPhoneAlt,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrapper,
  SocialMedia,
  SocialMediaWrapper,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  ExactRes,
} from "./FooterStyle";
import { CiLocationOn, CiAlarmOff } from "react-icons/ci";
import { IoIosTimer } from "react-icons/io";
import { animateScroll as scroll } from "react-scroll";
import "./footerStyle.css";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const language = localStorage.getItem("language");

  return (
    <FooterContainer id="about">
      <FooterWrapper>
        <SocialMedia>
          <SocialMediaWrapper>
            <div className="parent1">
              <SocialLogo to="/" onClick={toggleHome}>
                American Bite
              </SocialLogo>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/share/LrSsa4hUHquZJbjy/?mibextid=qi2Omg"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/american.bite/"
                  target="_blank"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://waze.com/ul/hsvc4k20n8"
                  target="_blank"
                  aria-label="Waze"
                >
                  <FaWaze />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.google.com/search?q=american+bite&oq=american+bite&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMhAIARAuGK8BGMcBGIAEGI4FMgwIAhAAGBQYhwIYgAQyBwgDEAAYgAQyBwgEEAAYgAQyBggFEEUYPTIGCAYQRRg8MgYIBxBFGDzSAQg3MzU3ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8"
                  target="_blank"
                  aria-label="Google"
                >
                  <FaGoogle />
                </SocialIconLink>
              </SocialIcons>
            </div>
            <div className="footer-section-columns">
              <span>{language === "He" ? "ליצירת קשר ומיקום": "للاتصال ولمكان"}</span>
              <span>
                <a href="tel:+9720524439922">
                  <FaPhoneAlt /> 0524439922
                </a>
              </span>
              <span>
                <a href="https://waze.com/ul/hsvc4k20n8">
                  <CiLocationOn />{language === "He" ? "כפר כנא דרב אל-שוק": "كفركنا درب السوق"}
                </a>
              </span>
            </div>

            <div className="footer-section-columns">
              <span>{language === "He" ? "שעות עבודת": "ساعات العمل"}</span>
              <span>
                <IoIosTimer />{language === "He" ? "ראשון": "الاحد"} - 12:00 - 23:55
              </span>
              <span>
                <IoIosTimer />{language === "He" ? "שני": "الاثنين"} - 12:00 - 23:55
              </span>
              <span>
                <IoIosTimer />{language === "He" ? "שלישי": "الثلاثاء"} - 12:00 - 23:55
              </span>
              <span>
                <CiAlarmOff />{language === "He" ? "רבעי - סגור": "الاربعاء - مغلق"}
              </span>
              <span>
                <IoIosTimer />{language === "He" ? "חדמשי": "الخميس"} - 12:00 - 23:55
              </span>
              <span>
                <IoIosTimer />{language === "He" ? "שיש": "الجمعه"} - 12:00 - 23:55
              </span>
              <span>
                <IoIosTimer />{language === "He" ? "שבת": "السبت"} - 12:00 - 23:55
              </span>
            </div>
          </SocialMediaWrapper>
        </SocialMedia>
      </FooterWrapper>
      <div className="rights">
        <WebsiteRights>
          {new Date().getFullYear()} All rights reserved to{" "}
          <ExactRes href="//www.exactres.com" target="_blank">
            ExactRes
          </ExactRes>
        </WebsiteRights>
      </div>
    </FooterContainer>
  );
};

export default Footer;
