import React from 'react'
import { MenuData } from "../../../Data/data";
import Product from './Product'
import { FiChevronLeft } from "react-icons/fi";
import {MenuContainer, MenuWrapper, MenuH1, Nav, NavbarContainer, Icon, HeroBg, ImageBg} from './MenuListStyle'


const MenuList = ({menuList}) => {
  const language = localStorage.getItem("language");
  return (
    <>
      <MenuContainer>
        <MenuWrapper>
          {MenuData[menuList].map((item, i) =>
            <Product
              key={i}
              name={language === "He" ? item.nameHe : item.nameAr}
              url={item.imageUrl}
              price={item.price}
              description={language === "He" ? item.descriptionHe :item.descriptionAr}
              />
            )}
          </MenuWrapper>
      </MenuContainer>
    </>
  )
}

export default MenuList