import React, {useEffect, useState} from 'react'
import {FaBars, FaPhoneVolume } from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo,
MobileIcon, NavMenu, NavItem, NavLinks,LogoImg,
NavBtnWrapper, NavBtn, MobileBtn, MobileBtnContainer, NavPhone, NavPhoneContainer} from './NavStyle'
import LogoImage from '../../assets/logo.png'
import {animateScroll as scroll} from 'react-scroll'


const NavBar = ({toggle, switchLanguage}) => {
  const [scrollNav, setScrollNav] = useState(false);
  const language = localStorage.getItem("language");

  const changeNav = () => {
    if(window.scrollY >= 80){
      setScrollNav(true)
    }else{
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  },[]);

const toggleHome = () => {
  scroll.scrollToTop();
}

  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <NavLogo to="/" onClick={toggleHome}>
          <LogoImg src={LogoImage}/>
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars/>
        </MobileIcon>
        <MobileBtnContainer>
          <MobileBtn onClick={switchLanguage}>{language === "Ar" ? "עברית" : "العربية"}</MobileBtn>
        </MobileBtnContainer>
        <NavMenu>
          <NavItem>
            <NavLinks 
              to="home" 
               smooth={true} 
              duration={500} 
              spy={true}
              exact='true' 
              offset={-60}
            >
              {language === "He" ? "בית": "الرئيسيه"}
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks 
              to="menu"
              smooth={true} 
              duration={500} 
              spy={true}
              exact='true' 
              offset={-60}
              >
              {language === "He" ? "תפריט המסעדה": "قائمة الطعام"}
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks 
              to="about"
              smooth={true} 
              duration={500} 
              spy={true}
              exact='true' 
              offset={-60}
              >
              {language === "He" ? "מידע": "معلومات"}
            </NavLinks>
          </NavItem>
          <NavBtnWrapper>
            <NavBtn onClick={switchLanguage}>{language === "Ar" ? "עברית" : "العربية"}</NavBtn>
          </NavBtnWrapper>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  )
}

export default NavBar