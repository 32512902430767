import styled from 'styled-components'

export const MenuContainer = styled.div`
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    padding-top: 20px;


    @media screen and (max-width: 768px){
        height: 500px;
    }
    @media screen and (max-width: 480px){
        height: 500px;
    }
    @media screen and (max-width: 280px){//galaxy fold
        height: 700px;
    }
`

export const MenuWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 50px;
    padding: 0 50px;

     /* @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    } */
     @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 20px;
        grid-gap: 20px;
    }
    @media screen and (max-width: 280px){//galaxy gold
        grid-template-columns: 1fr 1fr;
    }
`

export const MenuCard = styled.div`
    /* background: #fff; */
    /* display: flex;
    flex-direction: column; */
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 320px;
    /* padding: 5px;//30px */
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    // background-color: #000000;


    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        /* box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5); */
        transform: translateY(-10px);
    }
    /* @media screen and (max-width: 768px){
        
        padding: 10px;
    } */
`
export const MenuIcon = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    /* margin-bottom: 10px; */
    /* border-radius: 10px; */
    @media screen and (max-width: 768px){
        width: 80px;
        height: 80px;
    }
`

export const MenuH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const MenuH2Container = styled.div`
    // background: #000000;
    /* border-radius: 10px; */
    font-size: 10px;
    height: 30px;
    width: 100%;
    /* justify-content: center;
    text-align: center;
    align-items: center;
    opacity: 0.3; */
  
    /* padding: 10px; */
`

export const MenuH2 = styled.h2`
    font-size: 1rem;
    /* margin-bottom: 10px; */
    justify-content: center;
    text-align: center;
    color: white;
    max-width: 100px;
    min-width: 70px;
`
// export const MenuP = styled.p`
//     font-size: 1rem;
//     text-align: center;
// `


export const SectionLine = styled.div`
    height: 1px;
    width: 80%;
    background-color: grey;
    margin: 30px;
`






// export const MenuContainer = styled.div`
//     margin: 0 auto;
//     padding: 2rem;
//     text-align: center;
//     background-color: black;
// `

// export const MenuCard = styled.div`
//     height: 500px;
// `

// export const MenuCardH2 = styled.h2`
//     color: white;
//     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
//     padding-bottom: 15px;
// `

// export const MenuContainerH1 = styled.div`
//     margin: 1rem auto 4rem;
//     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
//     color: #fff;
//     font-size: 36px;
//     text-align: center;
//     padding-top: 5px;
// `

// export const Card = styled.div`
    // box-shadow: 0 2px 8px 0 rgb(0, 0, 0,0.5);
    // height: 380px;
    // background-color: white;
    // /* max-width: 380px; */
    // margin: auto 1rem;
    // text-align: center;
    // font-family: arial;
    // border-radius: 15px;
    // cursor: pointer;
    // transition: all 0.2s ease-in-out;

//     &:hover{
//         transform: scale(1.02);
//         transition: all 0.2s ease-in-out;
//         cursor: pointer;
//     }
// `

// export const CardH2 = styled.h2`
//     color:black;
//     font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
//     font-size: 20px;
// `

// export const CardProductImg = styled.img`
//     width: 100%;
//     height: 16em;
//     object-fit: cover;
//     border-radius: 15px;
// `

// export const CardPrice = styled.p`
//     color: grey;
//     font-size: 16px;
//     padding-top: 5px;
// `

// export const CardDescription = styled.p`
//     color: black;
//     padding-top: 10px;
//     font-size: 18px;
//     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
// `