import React from "react";
 import {ProductCard ,ProductImg, ProductH2, CardDescription, ProductPrice} from './ProductStyle'
export default function Product(props) {
    
  return (
    <>
    <ProductCard>
      <ProductImg src={props.url} alt="product" />
      <ProductH2>{props.name}</ProductH2>
      <CardDescription className="description">{props.description}</CardDescription>
      <ProductPrice>{props.price + " ₪"}</ProductPrice>
    </ProductCard>
    </>
  );
}