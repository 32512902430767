import styled from "styled-components";

export const LoaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    background: rgba(225,225,225,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`