import styled from 'styled-components';

export const MenuContainer = styled.div`
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
`;

export const MenuWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 50px;
    padding: 0 50px;

    @media screen and (max-width: 768px){
        grid-gap: 20px;
    }
    @media screen and (max-width: 330px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const MenuCard = styled.div`
    width: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-height: 320px;
    transition: all 0.2s ease-in-out;
    border: ${(props) => (props.isActive ? '2px solid red' : '2px solid #fff')}; /* Conditional border color */
    text-align: center;
    cursor: pointer;

    /* &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        transform: translateY(-10px);
        border-color: ${(props) => (props.isActive ? 'red' : '#ddd')}; 
    } */
`;

export const MenuH2Container = styled.div`
    font-size: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    opacity: 0.6;
`;

export const MenuH2 = styled.h2`
    font-size: 1rem;
    color: white;
    max-width: 100px;
    min-width: 70px;
`;

export const SectionLine = styled.div`
    height: 1px;
    width: 80%;
    background-color: grey;
    margin: 30px;
`;
