import './App.css';
import Home  from './pages/Home'
// import MenuList from './components/Menu/MenuList/MenuList';
import { BrowserRouter as Router , Routes, Route} from 'react-router-dom';

function App() {
  
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        {/* <Route exact path="/list" element={<MenuList/>}/> */}
      </Routes>
   </>
  );
}

export default App;