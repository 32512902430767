import styled from 'styled-components'

export const MenuContainer = styled.div`
    max-height: 2000px;
    /* min-height: 750px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    padding-bottom: 20px;

    @media screen and (max-width: 768px){
        max-height: 3500px;
    }
    @media screen and (max-width: 480px){
        max-height: 3500px;
    }
`

export const MenuWrapper = styled.div`
    max-width: 2000px;
    height: auto;
    /* margin: 0 auto; */
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    /* column-gap: 30px;//40 */
    /* padding: 60px; */
    row-gap: 30px;
    /* padding: 0 50px; */
    padding-bottom: 20px;

     /* @media screen and (max-width: 768px){
        grid-template-rows: 1fr; 
        padding: 0 20px; 
        grid-gap: 30px; 
        height: 700px;
    } */
`

export const MenuH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const Nav = styled.nav`

    background: #000;
    height: 60px;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80x;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
    justify-content: center;
    text-align: center;

`

export const Icon = styled.div`
    position: absolute;
    top: 0.8rem;
    left: 1.5rem;
    font-size: 2.5rem;
    cursor: pointer;
    outline: none;
`
export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`