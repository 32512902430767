import styled from "styled-components";
import {Link} from 'react-router-dom'

export const FooterContainer =styled.footer`
    background-color: #101522;

`

export const FooterWrapper = styled.div`
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    @media screen and (max-width: 1120px){
        height: 300px;
    }
    @media screen and (max-width: 920px){
        height: 500px;
    }
    @media screen and (max-width: 820px){
        height: 550px;
    }
    /* @media screen and (max-width: 768px){
        height: 210px;
    }
     @media screen and (max-width: 480px){
        height: 120px;
    }*/
    @media screen and (max-width: 350px){
        height: 700px;
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: auto 30px auto 30px;

    @media screen and (max-width: 1120px){
        margin: auto 50px auto 50px;
    }

     @media screen and (max-width: 920px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`

export const SocialLogo = styled(Link)`
   color:#fff;
   /* justify-self: start; */
   cursor: pointer;
   text-decoration: none;
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   font-weight: bold;
  
`

export const WebsiteRights = styled.small`
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding-top:10px;
    color: black;
`

export const SocialIcons = styled.div`
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    width: 24px;
     @media screen and (max-width: 820px){
        justify-content: center;
    }
`

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 30px;
    padding: 5px;
    /* margin: 10px; */
`

export const ExactRes = styled.a`
    color: black;
    font-size:18px;
    text-decoration: none;
    cursor: pointer;
`